import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import productApi from '../../../api/productApi.js';
import i18n from "../../../i18n.js";
import './categoryProductList.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import PaperCup from './component/paper_cup.js';

function CategoryProductList() {
    const param = useParams();
    const [productList, setProductList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const currentLanguage = i18n.language;
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
      }, []);

      useEffect(() => {
        AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const querySnapshot = await productApi.getCategory(param.uid);
                
                // Chuyển đổi dữ liệu từ Firestore thành mảng
                const tempProductArray = [];

                querySnapshot.forEach((doc) => {
                    tempProductArray.push({ ...doc.data(), id: doc.id });
                });

                setProductList(tempProductArray);

               
                setTotalPages(1);
            } catch (error) {
                console.log('Failed to fetch product list', error);
            }
        })();
    }, [currentPage, param.uid]);

    // Hàm xử lý khi click vào sản phẩm
    const handleCardClick = (categoryId,productId) => {
        navigate(`${productId}`);
    };

    // Hàm xử lý chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    
    function capitalizeFirstLetter(str) {
        if (!str) return str;
  return str
    .split(' ') // Tách chuỗi thành mảng các từ
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Viết hoa chữ cái đầu và chuyển phần còn lại thành chữ thường
    .join(' '); // Nối các từ lại thành chuỗi
      }

// document.title= (currentLanguage === "en" )? "Introduce":"Giới thiệu";
    return (
        <div>
            
            <Row md={8}>
                {productList.map((product, index) => (
                    <Col key={product.id} md={4} xs={6}>
                        <div
              >
                         <div className="product-card" data-aos="fade-up" data-aos-delay={index * 100} >
                        <Card style={{ marginBottom: '20px' }} onClick={() => handleCardClick(product.category.id, product.id)}>
                            <Card.Img variant="top" src={product.image} alt={product.name} />
                            {product.sale ? (
    <div className="sale-label">
        -{product.sale}%
    </div>
) : null}
                            <Card.Body style={{ textAlign: 'center' }}>
                                <Card.Title style={{ fontSize: '20px', padding: '0', textAlign: 'center', color:"#F59F05" }}><strong>{capitalizeFirstLetter((currentLanguage === "en" )? product.name:product.namevn)}</strong></Card.Title>
                                <Card.Text style={{color:'red',fontSize:'15' }} >{(currentLanguage === "en" )? product.Price: "Liên Hệ"}</Card.Text>
                            </Card.Body>
                        </Card>
                        </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <div>
                <Pagination className="justify-content-center mt-3">
                    <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                    {[...Array(totalPages).keys()].map((page) => (
                        <Pagination.Item
                            key={page + 1}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageChange(page + 1)}
                        >
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                    <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
                </Pagination>
            </div>
                
        </div>
    );
}

export default CategoryProductList;
