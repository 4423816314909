import React from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ZaloButton.css'

const ZaloButton = () => {
  return (
    <a
      className="btn-zalo btn-frame text-decoration-none hidden-xs"
      href="https://zalo.me/0397711112"
    >
      <div className="animated infinite zoomIn kenit-alo-circle" style={{border: '2px solid #0068FF'}}></div>
      <div className="animated infinite pulse kenit-alo-circle-fill" ></div>
      <div className="zalo-icon-container">
      <img className="zalo-icon" src="https://nicecupvn.com/assets/images/zl.png" alt="Zalo" />
      </div>
      
    </a>
  );
};

export default ZaloButton;
