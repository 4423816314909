import React from 'react';
import './CallButton.css'; // Import CSS cho styling
import { FaPhoneAlt } from 'react-icons/fa'; // Import icon từ react-icons

const CallNowButton = () => {

  return (
    <div className="call-now-button hidden-xs">
      <p className="call-text" style={{ display: 'table' }}>
        <a
          href="tel:+84397711112"
          title="039 771 1112"
          style={{ textDecoration: 'none', color: '#fff', paddingLeft: '10px' }}
        >
          <strong>039 771 1112</strong>
        </a>
      </p>
      <div className="icon">
        <a
          href="tel:+84397711112"
          title="039 771 1112"
        >
          <div className="animated infinite zoomIn quick-alo-ph-circle-call"></div>
          <div className="animated infinite pulse quick-alo-ph-circle-fill-call"></div>
          <div className="quick-alo-ph-btn-icon quick-alo-phone-img-circle">
            <FaPhoneAlt />
          </div>
        </a>
      </div>
    </div>
  );
};

export default CallNowButton;
