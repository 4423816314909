import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";
import { Helmet } from 'react-helmet';

const Technology = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
  const technologies = [
    {
      href: 'technology/plastic-production-process',
      title: 'Quy trình sản xuất sản phẩm nhựa',
      titleen:'Plastic product manufacturing process',
      imgSrc: 'https://nicecupvn.com/thumbs/530x560x1/upload/news/cong-ty-san-xuat-ly-nhua-uy-tin-va-chat-luong-o-tphcm-189680-4203.jpg',
      altText: 'Quy trình sản xuất sản phẩm nhựa',
      description: 'Quy trình sản xuất ly nhựa',
    },
    {
      href: 'technology/paper-production-process',
      title: 'Quy trình sản xuất sản phẩm giấy',
      titleen:'Paper product manufacturing process',
      imgSrc: 'https://nicecupvn.com/thumbs/530x560x1/upload/news/ly-giay-gia-re-chat-luoong-biogreen-5956.jpg',
      altText: 'Quy trình sản xuất sản phẩm giấy',
      description: 'Quy trình sản xuất ly giấy',
    },
  ];

  return (
    <div id="main" className="wrap-main">
       <Helmet>
            <title>{t('Technology')} - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t('Technology')}></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
      <Container className="maxwidth">
      <div className="maxwidth">
        <div className="content-main w-clear">
        <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Technology" active>
        {t('Technology')} 
        </Breadcrumb.Item>
      </Breadcrumb>
          <h1 className="title-main"><strong>{t('technology')}</strong></h1>
          <Row className="row-10">
            {technologies.map((tech, index) => (
              <Col key={index} md={3} sm={6} xs={6} className="padding-10">
                <Card className="newshome-normal text-decoration-none w-clear" >
                  <a href={tech.href} title={(currentLanguage==='en')?tech.titleen:tech.title}>
                    <Card.Img
                      src={tech.imgSrc}
                      alt={tech.altText}
                      onError={(e) => (e.target.src = 'https://nicecupvn.com/thumbs/530x560x2/assets/images/noimage.png')}
                    />
                    <Card.Body style={{color:'#ffffff', backgroundColor:'#F59F05', textAlign:'center'}}>
                      <Card.Title className="name-newshome single-line-ellipsis ">{(currentLanguage==='en')?tech.titleen:tech.title}</Card.Title>
                      
                    </Card.Body>
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Container>
    
    </div>
    
  );
};

export default Technology;
