import React, { useEffect } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { FaCalendarWeek } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';


const WarrantyPolicy = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
        
      }, []);
    const { t } = useTranslation();
    
    return (
        <Container className="wrap-main">
            <Helmet>
            <title>{t('Warranty policy')} - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t('Warranty policy')}></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
            <Row className="content-main w-clear">
            <Breadcrumb >
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item  active>
         {t('Warranty policy')}
        </Breadcrumb.Item>
      </Breadcrumb>
                <Col md={12}>

                <div className="title-main">
                <h2>{t("WARRANTY POLICY")} </h2>
                            </div>
                        
                    <div className="time-main">
                       
                        <span><FaCalendarWeek/> {t("Posted date")}: 05/22/2022 08:27 AM</span>
                    </div>
                    <div className="content-detail">
                        <div className="w-clear" id="toc-content">
                            <p style={{ textAlign: 'justify' }}><strong>1. {t("Warranty period")}:</strong></p>
                            <p style={{ textAlign: 'justify' }}>- {t("Warranty may apply for a specific period of time after the date of purchase.")}</p>

                            <p style={{ textAlign: 'justify' }}><strong>2. {t("Warranty conditions")}:</strong></p>
                            <p style={{ textAlign: 'justify' }}>- {t("Warranty applies only to products used properly according to the instructions for use.")}</p>
                            <p style={{ textAlign: 'justify' }}>- {t("The product has not been repaired or modified by the consumer or a third party.")}</p>

                            <p style={{ textAlign: 'justify' }}><strong>3. {t("Return process")}:</strong></p>
                            <p style={{ textAlign: 'justify' }}>- {t("Customers need to contact customer support to initiate the return process.")}</p>
                            <p style={{ textAlign: 'justify' }}>- {t("Requires proof of purchase and a detailed description of the problem.")}</p>

                            <p style={{ textAlign: 'justify' }}><strong>4. {t("Return options")}:</strong></p>
                            <p style={{ textAlign: 'justify' }}>- {t("Provide options for new product exchange, repair or refund depending on the specific situation.")}</p>

                            <p style={{ textAlign: 'justify' }}><strong>5. {t("Return costs")}:</strong></p>
                            <p style={{ textAlign: 'justify' }}>- {t("Return shipping costs may apply if the return is not due to a manufacturing defect.")}</p>

                            <p style={{ textAlign: 'justify' }}><strong>6. {t("Warranty limitations")}:</strong></p>
                            <p style={{ textAlign: 'justify' }}>- {t("Some conditions or products may not be covered by the warranty, which is clearly described in the policy.")}</p>
                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default WarrantyPolicy;
