import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button, ButtonGroup, Dropdown, Row, Col, Carousel } from 'react-bootstrap';
import './navbar.css';
import { FaSearch } from 'react-icons/fa';
import { PiTruck } from "react-icons/pi";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const CustomNavbar = () => {
  const { t } = useTranslation();
  const [showIntroduce, setShowIntroduce] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true); // Trạng thái để ẩn/hiện Navbar
  const [lastScrollY, setLastScrollY] = useState(0);  // Theo dõi vị trí cuộn trước đó

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  // Hàm theo dõi sự kiện cuộn
  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      // Nếu cuộn xuống, ẩn Navbar
      setShowNavbar(false);
    } else {
      // Nếu cuộn lên, hiện Navbar
      setShowNavbar(true);
    }
    setLastScrollY(window.scrollY); // Cập nhật vị trí cuộn trước đó
  };

  useEffect(() => {
    // Gắn sự kiện cuộn khi component được mount
    window.addEventListener('scroll', controlNavbar);

    // Hủy gắn sự kiện cuộn khi component bị unmount
    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [lastScrollY]);

  
  return (
    <div>
      <Navbar expand="lg" className={`navbar ${showNavbar ? 'navbar-visible' : 'navbar-hidden'}`} fixed="top">
        <Container>
          <Navbar.Brand href='/'>
            <img src='/logo.png' alt='logo' style={{ height: "70px", objectFit: "cover" }} />
          </Navbar.Brand>
          <div className="free-shipping d-flex d-lg-none align-items-center justify-content-start">
            
          
        </div>
          
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='ms-auto text-center d-flex justify-content-center align-items-center'>
              <Nav.Link href="/" className="nav-link">{t('home')}</Nav.Link>
              <Dropdown as={ButtonGroup}
                title={t('introduce')}
                id="nav-dropdown-introduce"
                className="nav-dropdown"
                onMouseEnter={() => setShowIntroduce(true)}
                onMouseLeave={() => setShowIntroduce(false)}
                show={showIntroduce}
              >
                <div className="d-flex justify-content-start align-left">
                  <Button className="button-introduce" href='/companyintroduction' style={{ paddingTop: '7px' }}>{t('introduce')}</Button>
                </div>
                <Dropdown.Menu>
                  <Dropdown.Item href="/Introduce">{t('Company introduction')}</Dropdown.Item>
                  <Dropdown.Item href="/certificate">{t('Certificate')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown as={ButtonGroup}
                title={t('product')}
                id="nav-dropdown-product"
                className="nav-dropdown"
                onMouseEnter={() => setShowProduct(true)}
                onMouseLeave={() => setShowProduct(false)}
                show={showProduct}
              >
                <div className="d-flex justify-content-start align-left">
                  <Button className="button-introduce" href='/Products' style={{ paddingTop: '7px' }}>{t('product')}</Button>
                </div>
                <Dropdown.Menu>
                  <Dropdown.Item href="/Products/1">{t('Paper Cup')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/2">{t('Plastic Cup')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/3">{t('Food Bowl')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/4">{t('Food Container')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/5">{t('Aluminum Foil Products')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/6">{t('Tissue')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/7">{t('Paper bag')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/8">{t('Bagasse Products')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/9">{t('Accompanying Products')}</Dropdown.Item>
                  <Dropdown.Item href="/Products/10">{t('Biodegradable Products')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Link href="/service" className="nav-link">{t('service')}</Nav.Link>
              <Nav.Link href="/technology" className="nav-link">{t('technology')}</Nav.Link>
              <Nav.Link href="/news" className="nav-link">{t('news')}</Nav.Link>
              <Nav.Link href="/contact" className="nav-link">{t('contact')}</Nav.Link>

              <div className="d-flex">
                <div className="search-box">
                  <input type="text" placeholder={t('search')} />
                  <FaSearch className="search-icon" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div onClick={() => handleLanguageChange('vi')}>
                    <img src='/images/homepage/vn.png' style={{ width: '60px', height: '45px', padding: '5px' }} alt='vietnam flag' />
                  </div>
                  <div onClick={() => handleLanguageChange('en')}>
                    <img src='/images/homepage/uk.png' style={{ width: '60px', height: '45px', padding: '5px' }} alt='england flag' />
                  </div>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
