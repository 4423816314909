import React, { useEffect } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import { FaCalendarWeek } from 'react-icons/fa';
import './supportSection.css'
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
import { Helmet } from 'react-helmet';

const SupportSection = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
// const currentLanguage = i18n.language;
  return (
    <Container  className="wrap-main">
      <Helmet>
            <title>{t('Design and printing support')} - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t('Design and printing support')}></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
      <div >
        <div className="">
        
          <Row>
            <div className='breacrumb'>
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Service" >
        {t('Service')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item href="service/design-and-printing-support" active >
        {t('Design and printing support')} 
        </Breadcrumb.Item>
      </Breadcrumb>
            </div>
            <Col md={12}>
            
              <h1 className="title-main"><strong>{t('DESIGN AND PRINTING SUPPORT')}</strong></h1>
              <div className="time-main">
                <FaCalendarWeek />
                <span>{t('Posted date')}: 23/01/2024 07:09 PM</span>
              </div>
              <div >
                <div  >
                  {/* <p>&nbsp;</p> */}
                  <p><strong>1. {t('Design consulting')}:</strong></p>
                  <p>- {t('Meet and consult with customers to understand their needs and requirements')}.</p>
                  <p>- {t('Provide ideas and design suggestions suitable for the product (paper cup, plastic cup) and customer\'s goals')}.</p>
                  <p><strong>2. {t('Graphic design')}:</strong></p>
                  <p>- {t('The design team creates 2D drawings and 3D models for the product with colors and images that match customer requirements')}.</p>
                  <p>- {t('Make sure the design highlights the customer\'s promotional message about the product')}.</p>
                  <p><strong>3. {t('Printing')}:</strong></p>
                  <p>- {t('Choose UV or Offset printing method to protect colors and images from external factors')}.</p>
                  <p>- {t('Use high quality ink to ensure the clarity and sharpness of printed images')}.</p>
                  <p><strong>4. {t('Quality control')}:</strong></p>
                  <p>- {t('Carry out strict quality control during printing and final control before delivery')}.</p>
                  <p>- {t('Ensure that all products meet quality standards and customer requirements')}.</p>
                </div>
                
              </div>
              {/* <div className="share othernews">
                <b>Bài viết khác:</b>
                <ListGroup as="ul" className="list-news-other">
                </ListGroup>
                
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default SupportSection;
