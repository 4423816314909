import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";
import { Helmet } from 'react-helmet';
import "./news.css"

const News = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
const newsItems = [
    {
      id: 1,
      title: "Công ty TNHH Khiêm Hòa TP Đông Quản tham hội chợ triển lãm tại Las Vegas (Mỹ)",
      titleen:"Khiem Hoa Co., Ltd. Dong Quan City attended the exhibition in Las Vegas (USA).",
      link: "news/exhibition-las-vegas",
      image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/las-vegas-show-06-1662.jpg",
      description: "Công ty TNHH Khiêm Hòa TP Đông Quản tham hội chợ triển lãm tại Las Vegas (Mỹ)"
    },
    {
      id: 2,
      title: "Công ty TNHH bao bì thực phẩm Khiêm Hòa TP Đông Quản thành lập chi nhánh tại Việt Nam",
      titleen:"Khiem Hoa Food Packaging Co., Ltd. Dongguan City established a branch in Vietnam",
      link: "news/establishe-a-branch",
      image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/vietnam-branch-7683.jpg",
      description: "Công ty TNHH bao bì thực phẩm Khiêm Hòa TP Đông Quản thành lập chi nhánh tại Việt Nam"
    },
    {
      id: 3,
      title: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice",
      titleen: "Annual meeting of Khiem Hoa and Nice company",
      link: "news/annual-meeting",
      image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/web-nice-2381.jpg",
      description: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice"
    },
    // {
    //   id: 4,
    //   title: "Các loại bao bì thực phẩm nào nên sử dụng? Sự lựa chọn thông minh cho sức khỏe và môi trường",
    //   titleen: "Annual meeting of Khiem Hoa and Nice company",
    //   link: "news/cac-loai-bao-bi",
    //   image: "/images/news/4.webp",
    //   description: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice"
    // },
    // {
    //   id: 5,
    //   title: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice",
    //   titleen: "Annual meeting of Khiem Hoa and Nice company",
    //   link: "news/annual-meeting",
    //   image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/web-nice-2381.jpg",
    //   description: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice"
    // },
    // {
    //   id: 6,
    //   title: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice",
    //   titleen: "Annual meeting of Khiem Hoa and Nice company",
    //   link: "news/annual-meeting",
    //   image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/web-nice-2381.jpg",
    //   description: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice"
    // },
    // {
    //   id: 7,
    //   title: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice",
    //   titleen: "Annual meeting of Khiem Hoa and Nice company",
    //   link: "news/annual-meeting",
    //   image: "https://nicecupvn.com/thumbs/530x560x1/upload/news/web-nice-2381.jpg",
    //   description: "Cuộc họp thường niên của công ty Khiêm Hòa và Nice"
    // }
  ];
  

  return (
    <div id="main" className="wrap-main">
      <Helmet>
            <title>{t('news')} | Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Tin tức, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content="{t('news')} Công Ty TNHH Quốc Tế Nice"></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
      <Container className="maxwidth">
      <div className="maxwidth">
        <div className="content-main w-clear">
        <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/news" active>
        {t('News')} 
        </Breadcrumb.Item>
      </Breadcrumb>
          <h1 className="title-main"><strong>{t('news')}</strong></h1>
          <Row className="row-10">
            {newsItems.map((tech, index) => (
              <Col key={index} md={3} sm={6} xs={6} className="padding-10" style={{paddingBottom: "20px"}}>
                <Card className="newshome-normal text-decoration-none w-clear">
                  <a href={tech.link} title={(currentLanguage==='en')?tech.titleen:tech.title}>
                    <Card.Img
                      className="pic-newshome-normal scale-img"
                      src={tech.image}
                      alt={tech.title}
                      onError={(e) => (e.target.src = 'https://nicecupvn.com/thumbs/530x560x2/assets/images/noimage.png')}
                    />
                    <Card.Body style={{color:'#ffffff', backgroundColor:'#4CAF50', textAlign:'center'}}>
                      <Card.Title className="name-newshome single-line-ellipsis">{(currentLanguage==='en')?tech.titleen:tech.title}</Card.Title>
                     
                    </Card.Body>
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Container>
    </div>
    
  );
};

export default News;
