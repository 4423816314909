import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import "./Contact.css";
import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../api/googleApi";
import { addDoc, collection } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    address: "",
    email: "",
    content: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    const imageRef = 'banner/contact-banner.jpg'; // Đường dẫn của ảnh trong Firebase Storage

    const fetchImageUrl = async () => {
      try {
        const storageRef = ref(storage, imageRef); // Tạo reference tới ảnh
        const url = await getDownloadURL(storageRef); // Lấy URL của ảnh từ Firebase Storage
        setImageUrl(url); // Lưu URL vào state
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageUrl();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const { fullName, phoneNumber, address, email, content } = formData;
  
    if (!fullName.trim() || !phoneNumber.trim() || !address.trim() || !email.trim() || !content.trim()) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }
  
    try {
      const docRef = await addDoc(collection(db, "contacts"), formData);
      console.log("Document written with ID: ", docRef.id);

      // Hiển thị modal thành công
      setShowModal(true);

      setFormData({ fullName: "", phoneNumber: "", address: "", email: "", content: "" });
      setError(null);
    } catch (e) {
      console.error("Error adding document: ", e);
      setError("Failed to submit form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div id="main" className="wrap-main">
      <Helmet>
            <title>Liên Hệ Đặt Hàng & Tư Vấn - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 039 771 1112 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content="Liên Hệ Đặt Hàng & Tư Vấn - Công Ty TNHH Quốc Tế Nice"></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
      <Container className="maxwidth">
        <img
          className="img-contact"
          src={imageUrl}
          alt="contact banner"
        />
        <div className="content-main w-clear">
          <div className="top-contact">
            <Row>
              <h1 className="title-main" style={{textAlign:'Center'}}><strong>{t("contact")}</strong></h1>
              <Col md={6} style={{backgroundColor:"rgb(217, 225, 232)",padding:'20px',marginBottom:'20px'}}>
                <div className="article-contact">
                  <div className="article-contact-in">
                    <p>
                      <span style={{ lineHeight: "1.5", fontSize: "16px" }}>
                        {t("Address: Lot B-1, Dien Bien Phu Street, Dai Dang Industrial Park, Phu Tan Ward, Thu Dau Mot City, Binh Duong Province")}
                      </span>
                    </p>
                    <p>
                      <span style={{ lineHeight: "1.5", fontSize: "16px" }}>
                        Hotline: 039 771 1112 
                      </span>
                    </p>
                    <p>
                      <span style={{ lineHeight: "1.5", fontSize: "16px" }}>
                        Email: monica.vn@qhcup.com
                      </span>
                    </p>
                    <p>
                      <span style={{ lineHeight: "1.5", fontSize: "16px" }}>
                        Website: niceecopackaging.com
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="article-contact">
                  <Form onSubmit={handleSubmit} className="validation-contact" noValidate>
                    <Row>
                      <Col sm={6} className="input-contact">
                        <Form.Group controlId="fullName">
                          <Form.Control
                            type="text"
                            placeholder={t("Full name")}
                            value={formData.fullName}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter your full name")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="input-contact">
                        <Form.Group controlId="phoneNumber">
                          <Form.Control
                            type="text"
                            placeholder={t("Phone number")}
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter your phone number")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} className="input-contact">
                        <Form.Group controlId="address">
                          <Form.Control
                            type="text"
                            placeholder={t("Address")}
                            value={formData.address}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter your address")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="input-contact">
                        <Form.Group controlId="email">
                          <Form.Control
                            type="email"
                            placeholder={t("Email")}
                            value={formData.email}
                            onChange={handleChange}
                            isInvalid={!!error}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            {t("Please enter a valid email address")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group controlId="content" className="input-contact">
                      <Form.Control
                        as="textarea"
                        placeholder={t("Content")}
                        value={formData.content}
                        onChange={handleChange}
                        isInvalid={!!error}
                        style={{ height: '200px' }}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {t("Please enter your message")}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {error && <p className="text-danger">{error}</p>}
                    <Button type="submit" className="btn btn-primary" disabled={loading}>
                      {loading ? t("Sending...") : t("Send")}
                    </Button>
                    <Button type="reset" className="btn btn-secondary" onClick={() => setFormData({ fullName: "", phoneNumber: "", address: "", email: "", content: "" })}>
                      {t("Reset")}
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
          <div className="bottom-contact">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125338.75550349108!2d106.6258646708481!3d10.975740308663676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d1e7f6e7cb6d%3A0xe5f7da867b195106!2sCty%20tnhh%20nice!5e0!3m2!1svi!2s!4v1706081310579!5m2!1svi!2s"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
        </div>
      </Container>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Success")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("Your message has been sent successfully! We will contact you as soon as possible.")}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Contact;
