import React, { useEffect, useState } from 'react';
import { Card, Col, Pagination, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProductData from '../../../api/productApi';
import i18n from "../../../i18n";
import './productGroupList.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';

const productsPerPage = 15;

function ProductGrouplist() {
    const [productList, setProductList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const currentLanguage = i18n.language;

    useEffect(() => {
        AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
    }, []);

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage);
        }
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const querySnapshot = await ProductData.getProductsWithPagination(productsPerPage, lastDoc);
                const tempProductArray = [];

                querySnapshot.forEach((doc) => {
                    tempProductArray.push({ ...doc.data(), id: doc.id });
                });

                const newLastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                setLastDoc(newLastDoc);

                setProductList(tempProductArray);

                const totalDocsSnapshot = await ProductData.getAllProduct();
                setTotalPages(Math.ceil(totalDocsSnapshot.size / productsPerPage));

            } catch (error) {
                console.log('Failed to fetch product list', error);
            }
        };
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchProducts();
    }, [currentPage]);

    const handlePageChange = async (pageNumber) => {
        setCurrentPage(pageNumber);
        if (pageNumber === 1) {
            setLastDoc(null);
        } else {
            const querySnapshot = await ProductData.getProductsWithPagination(productsPerPage * (pageNumber - 1), null);
            const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastDoc(lastVisibleDoc);
        }
    };

    const handleCardClick = (category_id, productId) => {
        navigate(`/Products/${category_id}/${productId}`);
    };

    function capitalizeFirstLetter(str) {
        if (!str) return str;
        return str
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    

    return (
        <div>
            <Helmet>
            <title>Tất cả Sản phẩm - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Ly nhựa, ly giấy, tô giấy, chai nhựa, ống hút, muỗng nhựa, khăn giấy... Uy tín, chất lượng hàng đầu Việt Nam - Công Ty TNHH Quốc Tế Nice"/>
            <meta name="keywords" content="Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content="Tất cả Sản phẩm - Công Ty TNHH Quốc Tế Nice"></meta>
            <meta property="og:description" content="Ly nhựa, ly giấy, tô giấy, chai nhựa, ống hút, muỗng nhựa, khăn giấy... Uy tín, chất lượng hàng đầu Việt Nam - Công Ty TNHH Quốc Tế Nice"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
            <Row>
                {productList.map((product, index) => (
                    <Col key={product.id} md={4} xs={6}>
                        <div className="product-card" data-aos="fade-up" data-aos-delay={index * 100}>
                            <Card style={{ marginBottom: '20px', position: 'relative' }} onClick={() => handleCardClick(product.category.id, product.id)}>
                                <Card.Img variant="top" src={product.image} alt={product.name} />
                                {product.sale ? (
                                    <div className="sale-label">-{product.sale}%</div>
                                ) : null}
                                <Card.Body style={{ textAlign: 'center' }}>
                                    <Card.Title style={{ fontSize: '20px', padding: '0', color: "#F59F05" }}>
                                        <strong>{capitalizeFirstLetter((currentLanguage === "en") ? product.name : product.namevn)}</strong>
                                    </Card.Title>
                                    <Card.Text style={{ color: 'red', fontSize: '15px' }}>
                                        {(currentLanguage === "en") ? product.Price : "Liên Hệ"}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                ))}
            </Row>

            <Pagination className="justify-content-center mt-3">
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {[...Array(totalPages).keys()].map((page) => (
                    <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {page + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
        </div>
    );
}

export default ProductGrouplist;
