import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Col, ListGroup, Row, Tab, Button, Offcanvas, Container } from 'react-bootstrap';
import { Outlet, useLocation } from 'react-router-dom';
import ProductGrouplist from './component/productGroupList/productGroupList';
import './products.css';
import { TiThMenu } from "react-icons/ti";
import { Helmet } from 'react-helmet';


function Products() {
  const [product, setProduct] = useState("Products");  // Tiêu đề mặc định
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(location.pathname);
  const [showMenu, setShowMenu] = useState(false); // State cho menu

  
  // Cập nhật tiêu đề dựa trên selectedItem
  useEffect(() => {
    switch (selectedItem) {
      case '/Products/1':
        setProduct('Paper Cup');
        break;
      case '/Products/2':
        setProduct('Plastic Cup');
        break;
      case '/Products/3':
        setProduct('Food Bowl');
        break;
      case '/Products/4':
        setProduct('Food Container');
        break;
      case '/Products/5':
        setProduct('Aluminum Foil Products');
        break;
      case '/Products/6':
        setProduct('Tissue');
        break;
      case '/Products/7':
        setProduct('Paper bag');
        break;
      case '/Products/8':
        setProduct('Bagasse Products');
        break;
      case '/Products/9':
        setProduct('Accompanying Products');
        break;
      case '/Products/10':
        setProduct('Biodegradable Products');
        break;
      default:
        setProduct("Products");
    }
  }, [selectedItem, t]); // Thay đổi khi selectedItem hoặc t (i18n) thay đổi

  const handleSelect = (eventKey) => {
    setSelectedItem(eventKey); // Cập nhật selectedItem khi người dùng chọn mục
    setShowMenu(false); // Đóng menu sau khi chọn mục
  };

  const hasCategoryRoute = location.pathname.includes('/Products/');

  return (

    <Container>
      <Helmet>
            <title>{t(product)} Chất Lương Cao Giá Rẻ, Đầy Đủ Mẫu Mã, Kích Thước, In Ấn Theo Yêu Cầu, Gửi Mẫu Miễn Phí,... - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Chuyên sản xuất các loại Ly nhựa, ly giấy, tô giấy, chai nhựa, ống hút, muỗng nhựa, khăn giấy... Uy tín, chất lượng hàng đầu Việt Nam. Liên hệ ngay để được báo giá sớm nhất"/>
            <meta name="keywords" content={t(product) + ", Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"}/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <link rel="icon" href="./logo.png" />
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t(product)}></meta>
            <meta property="og:description" content="Chuyên sản xuất các loại Ly nhựa, ly giấy, tô giấy, chai nhựa, ống hút, muỗng nhựa, khăn giấy... Uy tín, chất lượng hàng đầu Việt Nam. Liên hệ ngay để được báo giá sớm nhất"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
      <div >
      <img src='/bannernoel.png' alt='Banner cho Noel' style={{width:"100%", height:"auto", marginTop:"85px", marginBottom:"10px"}}></img>
      <Breadcrumb className='breadcrumb-main'>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Products">{t('Products')}</Breadcrumb.Item>
        {selectedItem && (
          <Breadcrumb.Item active>{t(product)}</Breadcrumb.Item>
        )}
      </Breadcrumb>

      {/* Nút để mở menu trên thiết bị di động */}
      <Button  
        className="d-lg-none" // Ẩn nút này trên màn hình lớn
        onClick={() => setShowMenu(true)}
        style={{background:"#65b741"}}
      >
        <TiThMenu />
      </Button>

      {/* Offcanvas cho menu sản phẩm */}
      <Offcanvas show={showMenu} onHide={() => setShowMenu(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t('PRODUCT CATALOG')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            <ListGroup.Item action href='/Products/1' active={selectedItem === '/Products/1'} onClick={() => handleSelect('/Products/1')}>
              {t('Paper Cup')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/2' active={selectedItem === '/Products/2'} onClick={() => handleSelect('/Products/2')}>
              {t('Plastic Cup')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/3' active={selectedItem === '/Products/3'} onClick={() => handleSelect('/Products/3')}>
              {t('Food Bowl')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/4' active={selectedItem === '/Products/4'} onClick={() => handleSelect('/Products/4')}>
              {t('Food Container')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/5' active={selectedItem === '/Products/5'} onClick={() => handleSelect('/Products/5')}>
              {t('Aluminum Foil Products')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/6' active={selectedItem === '/Products/6'} onClick={() => handleSelect('/Products/6')}>
              {t('Tissue')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/7' active={selectedItem === '/Products/7'} onClick={() => handleSelect('/Products/7')}>
              {t('Paper bag')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/8' active={selectedItem === '/Products/8'} onClick={() => handleSelect('/Products/8')}>
              {t('Bagasse Products')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/9' active={selectedItem === '/Products/9'} onClick={() => handleSelect('/Products/9')}>
              {t('Accompanying Products')}
            </ListGroup.Item>
            <ListGroup.Item action href='/Products/10' active={selectedItem === '/Products/10'} onClick={() => handleSelect('/Products/10')}>
              {t('Biodegradable Products')}
            </ListGroup.Item>
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      <Row>
        <Col md={4} className="d-none d-lg-block"> {/* Ẩn menu trên thiết bị di động */}
          <div className="menu-product">
            <Tab.Container id="list-group-tabs-example" onSelect={handleSelect}>
              <Row>
                <ListGroup>
                  <ListGroup.Item className='title-menu'>{t('PRODUCT CATALOG')}</ListGroup.Item>
                  <ListGroup.Item action href='/Products/1' active={selectedItem === '/Products/1'}>
                    {t('Paper Cup')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/2' active={selectedItem === '/Products/2'}>
                    {t('Plastic Cup')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/3' active={selectedItem === '/Products/3'}>
                    {t('Food Bowl')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/4' active={selectedItem === '/Products/4'}>
                    {t('Food Container')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/5' active={selectedItem === '/Products/5'}>
                    {t('Aluminum Foil Products')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/6' active={selectedItem === '/Products/6'}>
                    {t('Tissue')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/7' active={selectedItem === '/Products/7'}>
                    {t('Paper bag')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/8' active={selectedItem === '/Products/8'}>
                    {t('Bagasse Products')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/9' active={selectedItem === '/Products/9'}>
                    {t('Accompanying Products')}
                  </ListGroup.Item>
                  <ListGroup.Item action href='/Products/10' active={selectedItem === '/Products/10'}>
                    {t('Biodegradable Products')}
                  </ListGroup.Item>
                </ListGroup>
              </Row>
            </Tab.Container>
          </div>
        </Col>
        <Col md={8}>
          <h1 className='product-title' style={{paddingBottom:'30px',paddingTop:"10px", textAlign:'center', color:'#F59F05'}}><strong>{t(product).toUpperCase()}</strong></h1>
          {!hasCategoryRoute ? (
            <ProductGrouplist />
          ) : (
            <Outlet />
          )}
        </Col>
      </Row>
    </div>
    </Container>
    
  );
}

export default Products;
