import React, { useEffect } from 'react';
import { Container, Row, Col,  Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";
import { FaCalendarWeek } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const PaymentPolicy = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
        
      }, []);
    const { t } = useTranslation();
  return (
    <div id="main" className="wrap-main" >
      <Helmet>
            <title>{t('Payment policy')} - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t('Payment policy')}></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
        <Container className="maxwidth">
        <div >
          <Row>
            <Col md={12}>
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item  active>
        {t('Payment policy')} 
        </Breadcrumb.Item>
      </Breadcrumb>
              <div className="title-main">
                <h1><strong>{t("PAYMENT POLICY")}</strong></h1>
              </div>
              <div className="time-main">
                
                <span><FaCalendarWeek/> {t("Posted date")}: 05/22/2022 08:27 AM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" id="toc-content">
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>1. {t("Payment method")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("Identify accepted payment methods, such as credit card payments, bank transfers, or online payment methods.")}
                  </p>
                  
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>2. {t("Payment before and after")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("Specify whether the company requires pre-delivery payment, payment upon receipt of goods, or has any other policies regarding payment.")}
                  </p>
                 
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>3. {t("Credit limit")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("If applicable, disclose credit limits to regular customers and regulations regarding credit limit increases.")}
                  </p>
                  
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>4. {t("Invoice details")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Policy on how to provide invoice details, including product information, price, shipping costs, and any other fees.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>5. {t("Regulations on late payment")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Information about costs or consequences if the customer pays late compared to the payment terms.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>6. {t("Tax policy")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Provide information on whether or not taxes are applicable and how taxes are calculated on payment invoices.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>7. {t("Payment security information")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Ensure that customer payment information is protected and maintained according to security standards.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>8. {t("Return and refund policy")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("If applicable, the return and refund policy, including applicable periods and relevant conditions.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    {t("Payment policies help create a transparent and fair payment process between the company and customers, while minimizing risks and disputes.")}
                  </p>
                </div>
                
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      </div>
  );
};

export default PaymentPolicy;
