import React, { useEffect } from 'react';
import { Container, Row, Col, Image, Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
import { FaCalendarWeek } from 'react-icons/fa';
import { Helmet } from 'react-helmet';


const ExhibitionLasVegas = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
        
      }, []);
    const { t } = useTranslation();
  return (
    <div id="main" className="wrap-main">
      <Helmet>
            <title>{t('Khiem Hoa Co., Ltd. Dong Quan City attended the exhibition in Las Vegas (USA)')} </title>
            <meta name="description" content={t("In August 2018, Khiem Hoa Food Packaging Co., Ltd. participated and achieved unexpected success at the exhibition in Las Vegas, USA. At this event, the company had the opportunity to meet and interact with potential customer groups, expand its consumer market and create a great impact on the international market. This has helped to consolidate the company's leading position in the paper and plastic food packaging industry")}/>
            <meta name="keywords" content="Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t('Khiem Hoa Co., Ltd. Dong Quan City attended the exhibition in Las Vegas (USA)')}></meta>
            <meta property="og:description" content={t("In August 2018, Khiem Hoa Food Packaging Co., Ltd. participated and achieved unexpected success at the exhibition in Las Vegas, USA. At this event, the company had the opportunity to meet and interact with potential customer groups, expand its consumer market and create a great impact on the international market. This has helped to consolidate the company's leading position in the paper and plastic food packaging industry")}></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
      <Container className="maxwidth">
      <div className="content-main w-clear">
        <Row>
          <Col md={12}>
          <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/news" >
        {t('News')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item  active>
        {t('Khiem Hoa Co., Ltd. Dong Quan City attended the exhibition in Las Vegas (USA)')} 
        </Breadcrumb.Item>
      </Breadcrumb>
            <div className="title-main">
              <h1><strong>{t("KHIEM HOA CO., LTD. DONG QUAN CITY ATTENDED THE EXHIBITION IN LAS VEGAS (USA)")}</strong></h1>
            </div>
            <div className="time-main">
              
              <span><FaCalendarWeek/> {t("Posted date")}: 01/23/2024 07:08 PM</span>
            </div>
            <div className="content-detail">
              <p style={{ textAlign: 'justify' }}>
                {t("In August 2018, Khiem Hoa Food Packaging Co., Ltd. participated and achieved unexpected success at the exhibition in Las Vegas, USA. At this event, the company had the opportunity to meet and interact with potential customer groups, expand its consumer market and create a great impact on the international market. This has helped to consolidate the company's leading position in the paper and plastic food packaging industry")}.
              </p>
              <p style={{ textAlign: 'center' }}>
                <Image src="https://nicecupvn.com//upload/elfinder/las-vegas-show-05.jpg" height="450" width="600" alt="Nice International Company Limited" />
              </p>
              <p style={{ textAlign: 'justify' }}>
              {t("Khiem Hoa Food Packaging Co., Ltd. is proud to be a trusted address for products such as paper cups, paper bowls, paper bags, paper boxes, paper towels, and other related products. At the same time, if you are looking for products such as plastic cups, plastic boxes, plastic lids, aluminum tableware, and PLA biodegradable products, Khiem Hoa Company is the best choice")}.
              </p>
              <p style={{ textAlign: 'center' }}>
                <Image src="https://nicecupvn.com//upload/elfinder/las-vegas-show-03.jpg" height="450" width="600" alt="Nice International Company Limited" />
              </p>
              <p style={{ textAlign: 'justify' }}>
              {t("The company is committed to providing the best quality products and services, and promises to ensure on-time and on-site delivery. Our focus on quality and customer satisfaction is our top priority, and we will continue to strive to maintain and strengthen our reputation in the food packaging industry")}.
              </p>
              <p style={{ textAlign: 'center' }}>
                <Image src="https://nicecupvn.com//upload/elfinder/las-vegas-show-04.jpg" height="450" width="600" alt="Nice International Company Limited" />
              </p>
            </div>
            
          </Col>
        </Row>
      </div>
    </Container>
    </div>
    
  );
};

export default ExhibitionLasVegas;
