import React, { useEffect } from 'react';
import './BestSellingProduct.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

const BestSellingProducts = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
 
  };

  const products = [
    {
      id: 1,
      name: 'Ly Giấy Trung Không',
      image: '/images/san-pham-ban-chay/ly-giay-trung-khong.jpg',
      nameen:'Medium Paper Cup',
      href:'/Products/1/19',
    },
    {
      id: 2,
      name: 'Tô Giấy Đựng Kem',
      image: '/images/san-pham-ban-chay/to-giay-dung-kem.jpg',
      nameen:'Paper Ice Cream Bowl',
      href:'/Products/3/24',
    },
    {
      id: 3,
      name: 'Ly Giấy 2 PE',
      image: '/images/san-pham-ban-chay/ly-giay-2pe.jpg',
      nameen:'2 PE Paper Cup',
      href:'/Products/1/21',
    },
    {
      id: 4,
      name: 'Tô Giấy Hình Tròn',
      image: '/images/san-pham-ban-chay/to-giay-hinh-tron.jpg',
      nameen:'Round Paper Bowl',
      href:'/Products/3/30',
    },
    {
      id: 5,
      name: 'Sản Phẩm Bột Ngô Tự Phân Hủy',
      image: '/images/san-pham-ban-chay/san-pham-bot-ngo-tu-phan-huy.jpg',
      nameen:'Biodegradable Cornstarch Products',
      href:'/Products/10/65',
    },
    {
      id: 6,
      name: 'Ly Giấy 1 PE',
      image: '/images/san-pham-ban-chay/ly-giay-1pe.jpg',
      nameen:'1 Pe Paper Cup',
      href:'/Products/1/22',
    },
    {
      id: 7,
      name: 'Ly Gợn Sóng',
      image: '/images/san-pham-ban-chay/ly-gon-song.jpg',
      nameen:'Corrugated Paper Cup',
      href:'/Products/1/03',
    },
    {
      id: 8,
      name: 'Hộp Tự Khóa',
      image: '/images/san-pham-ban-chay/hop-tu-khoa.jpg',
      nameen:'Self-locking Box',
      href:'/Products/4/38',
    },
    {
      id: 9,
      name: 'Ly Và Nắp Bã Mía',
      image: '/images/san-pham-ban-chay/ly-va-nap-ba-mia.jpg',
      nameen:'Bagasse Cup And Lid',
      href:'/Products/8/51',
    },
  ];

  return (
    <section className="partners-carousel">
      <h2 className="title"><strong>{t('BEST SELLING PRODUCTS')}</strong></h2>
      <Slider {...settings}>
        {products.map((product) => (
          <a href={product.href} style={{textDecoration: 'none'}} className="product-card">
            <Card >
          <Card.Img src={product.image} ></Card.Img>
          <Card.Body>
          <Card.Title className="single-line-ellipsis">{(currentLanguage === "en" )? product.nameen:product.name}</Card.Title>
          <Card.Text style={{color:'red'}}>{(currentLanguage === "en" )? "Contact" :"Liên hệ"}</Card.Text>
          </Card.Body>
         
        </Card></a>
          
        ))}
      </Slider>
    </section>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-next" onClick={onClick}></div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-prev" onClick={onClick}></div>;
};

export default BestSellingProducts;
