import React, { useEffect } from 'react';
import { Container, Row, Col,   Breadcrumb } from 'react-bootstrap';
import { FaCalendarWeek } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
import { Helmet } from 'react-helmet';
const PurchasePaymentMethod = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();
  return (
    <Container  className="wrap-main">
      <Helmet>
            <title>{t('Purchase and payment methods')}  - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Phương thức mua hàng và thanh toán, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần, Công Ty TNHH Quốc Tế Nice"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t('Purchase and payment methods')} ></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
      <div >
        <div className="content-main w-clear">
          <Row>
          <div >
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Service" >
        {t('Service')} 
        </Breadcrumb.Item>
        <Breadcrumb.Item  active >
        {t('Purchase and payment methods')} 
        </Breadcrumb.Item>
      </Breadcrumb>
            </div>
            <Col md={12}>
              <h1 className="title-main"><strong>{t('PURCHASE AND PAYMENT METHODS')}</strong></h1>
              <div className="time-main">
                <FaCalendarWeek />
                <span> {t('Posted date')}: 23/01/2024 07:09 PM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" >
                  <p >
                    <strong>{t('Order')}: </strong>{t('Contact sales staff directly for quick and timely advice and quotation')}.<br />
                    <strong>{t('Payment')}: </strong>{t('Customers deposit 50% of the total order, the remaining amount is paid when the goods are shipped')}.
                  </p>
                </div>
            
            
              </div>
              {/* <div className="share othernews">
                <b>Bài viết khác:</b>
                <ListGroup as="ul" className="list-news-other">
                  
                </ListGroup>
                <div className="pagination-home"></div>
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default PurchasePaymentMethod;
