import React, { useEffect } from 'react';
import './Certificate.css';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { FaCalendarWeek } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const Certificate = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
      }, []);
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
document.title= (currentLanguage === "en" )? "Certificate":"Giấy chứng nhận";

    return (
        <div id="main" className="wrap-main">
            <Helmet>
            <title>Giấy Chứng Nhận Đạt Chuẩn | Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Doanh nghiệp sản xuất đã thông qua chứng nhận hệ thống quản lý an toàn thực phẩm ISO22000 và chứng nhận bảo vệ rừng FSC"/>
            <meta name="keywords" content="Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần, Công Ty TNHH Quốc Tế Nice"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content="Giấy Chứng Nhận Đạt Chuẩn | Công Ty TNHH Quốc Tế Nice"></meta>
            <meta property="og:description" content="Doanh nghiệp sản xuất đã thông qua chứng nhận hệ thống quản lý an toàn thực phẩm ISO22000 và chứng nhận bảo vệ rừng FSC"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
            <div className="maxwidth">
          
                <div className="content-main w-clear">
                <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item href="/Certificate" active>
        {t('Certificate')}
        </Breadcrumb.Item>
      </Breadcrumb>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-main">
                                <h1><strong>{(t('Certificate').toUpperCase())}</strong></h1>
                            </div>
                            <div className="time-main">
                                <i className="fas fa-calendar-week"></i>
                                <span><FaCalendarWeek/> {t('Date posted: January 23, 2024 09:19 PM')}</span>
                            </div>
                            <div className="content-detail" id="toc-content">
                                <p>
                                {t('The manufacturing enterprise has passed ISO22000 food safety management system certification and FSC forest protection certification.')}
                                </p>

                                <p>
                                    <img
                                        alt="certificate"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/cer-01.jpg"
                                        style={{ float: 'left' }}
                                        width="276"
                                    />
                                </p>

                                <p>
                                    <img
                                        alt="Nice International LLC"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/cer-02.jpg"
                                        style={{ float: 'left' }}
                                        width="276"
                                    />
                                    <img
                                        alt="Nice International LLC"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/cer-03.jpg"
                                        width="276"
                                    />
                                    <img
                                        alt="Nice International LLC"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/cer-04.jpg"
                                        width="276"
                                    />
                                </p>

                                <p>
                                    <img
                                        alt="accept"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/cer-07.jpg"
                                        width="276"
                                    />
                                    <img
                                        alt="certification"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/cer-08.jpg"
                                        width="276"
                                    />
                                </p>

                                <p>
                                    <img
                                        alt="Nice International LLC"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/c%20nhaan%201.jpg"
                                        width="276"
                                    />
                                    <img
                                        alt="Nice International LLC"
                                        height="372"
                                        src="https://nicecupvn.com//upload/elfinder/chuwng%20nhaj%202.jpg"
                                        width="276"
                                    />
                                </p>
                            </div>
                            </div>
                            </div>
                </div>
            </div>
        </div>
    );
};

export default Certificate;
