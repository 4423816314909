import React, { useEffect } from 'react';
import './Industrylist.css' 
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
import AOS from 'aos';
import 'aos/dist/aos.css';

const IndustryList = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out', once: true });
}, []);

  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();

  // Array of categories
  const categories = [
    { href: '/Products/1', alt: 'Paper Cup', src: "/images/homepage/ly-dung-do-uong.jpg", title: 'Paper Cup' },
    { href: '/Products/3', alt: 'Food Bowl', src: '/images/homepage/to-dung-do-an.jpg', title: 'Food Bowl' },
    { href: '/Products/4', alt: 'Food Container', src: '/images/homepage/hop-dung-do-an.jpg', title: 'Food Container' },
    { href: '/Products/7', alt: 'Paper bag', src: '/images/homepage/tui-giay.jpg', title: 'Paper bag' },
    { href: '/Products/2', alt: 'Plastic Cup', src: '/images/homepage/ly-nhua.jpg', title: 'Plastic Cup' },
    { href: '/Products/8', alt: 'Bagasse Products', src: '/images/homepage/san-pham-ba-mia.jpg', title: 'Bagasse Products' },
    { href: '/Products/9', alt: 'Accompanying Products', src: '/images/homepage/san-pham-phoi-kem.jpg', title: 'Accompanying Products' },
    { href: '/Products/10', alt: 'Biodegradable Products', src: '/images/homepage/san-pham-phan-huy.jpg', title: 'Biodegradable Products' },
  ];

  return (
    <section id="category-index" >
      <div className="maxwidth aos-init aos-animate" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="1000">
        <div className="flashsale-in">
          <div >
            <h2 className="title" style={{color:'#F59F05', paddingTop:'20px'}}> <strong>{t('INDUSTRY LIST')}</strong></h2>
          </div>
          <div className="category-all row">
            {categories.map((category, index) => (
              <div
                key={index}
                className="col-md-3 col-sm-6 col-6"
                data-aos="fade-up"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000"
              >
                <div className='category-card' >
                  <a href={category.href} style={{textDecoration: 'none'}}>
                  <Card  >
                    <Card.Img src={category.src} alt={category.title} ></Card.Img>
                    <Card.Body style={{backgroundColor:'#F59F05', color:'#ffffff', textAlign:'center'}}>
                      <Card.Title>{t(`${category.title}`)}</Card.Title>
                    </Card.Body>
                  </Card>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustryList;
