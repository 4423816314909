import React, { useEffect, useState } from 'react';
import './Footer.css' 
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../api/googleApi';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";

const Footer = () => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
    
  }, []);
const { t } = useTranslation();

  useEffect(() => {
    const imageRef = 'footer/zalo.jpg'; // Đường dẫn của ảnh trong Firebase Storage

    const fetchImageUrl = async () => {
      try {
        const storageRef = ref(storage, imageRef); // Tạo reference tới ảnh
        const url = await getDownloadURL(storageRef); // Lấy URL của ảnh từ Firebase Storage
        setImageUrl(url); // Lưu URL vào state
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageUrl();
  }, []);
  return (
    <div id="footer">
      <div className="footer-article">
        <div className="maxwidth">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-news">
                <h2 className="title-footer">{t('About us')}</h2>
                <ul className="footer-ul">
                  <li><a className="text-decoration-none" href="/CompanyIntroduction" title="Giới thiệu công ty">{t('Company introduction')}</a></li>
                  <li><a className="text-decoration-none" href="/Certificate" title="Giấy chứng nhận ">{t('Certificate')} </a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-news">
                <h2 className="title-footer">{t('Customer service')}</h2>
                <ul className="footer-ul">
                  <li><a className="text-decoration-none" href="/service/design-and-printing-support" title="Printing and design ">{t('Printing and design')} </a></li>
                  <li><a className="text-decoration-none" href="/service/purchase-payment-method" title="Order form">{t('Order form')}</a></li>
                  <li><a className="text-decoration-none" href="chuong-trinh-khuyen-mai" title="Promotional program">{t('Promotional program')}</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-news">
                <h2 className="title-footer">{t('Support policy')}</h2>
                <ul className="footer-ul">
                  <li><a className="text-decoration-none" href="/sales-policy" title={t('Sales policy')}>{t('Sales policy')}</a></li>
                  <li><a className="text-decoration-none" href="/warranty-policy" title={t('Warranty policy')}>{t('Warranty policy')}</a></li>
                  <li><a className="text-decoration-none" href="/payment-policy" title={t('Payment policy')}>{t('Payment policy')}</a></li>
                  <li><a className="text-decoration-none" href="/support-policy" title={t('Support policy')}>{t('Support policy')}</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-news">
                <h2 className="title-footer">{t('Connect with us')}</h2>
                <div className="follow-us">
                  <div>
                    <a href="https://shp.ee/pjpi7ve" target="_blank" rel="noopener noreferrer" title="">
                      <img src="https://nicecupvn.com/upload/photo/mxhf6-71951.png" alt="Công Ty TNHH Quốc Tế Nice" onError={(e) => e.currentTarget.src = 'thumbs/25x25x2/assets/images/noimage.png'} />
                    </a>
                  </div>
                  <div>
                    <a href="https://www.tiktok.com/@bao.b.thc.phm.nic?_t=8pqLOpbNXBi&_r=1" target="_blank" rel="noopener noreferrer" title="">
                      <img src="https://nicecupvn.com/upload/photo/mxhf5-30690.png" alt="Công Ty TNHH Quốc Tế Nice" onError={(e) => e.currentTarget.src = 'https://nicecupvn.com/thumbs/25x25x2/assets/images/noimage.png'} />
                    </a>
                  </div>
                  <div>
                    <a href="https://s.lazada.vn/s.35JcS" target="_blank" rel="noopener noreferrer" title="">
                      <img src="https://nicecupvn.com/upload/photo/mxhf4-96231.png" alt="Công Ty TNHH Quốc Tế Nice" onError={(e) => e.currentTarget.src = 'https://nicecupvn.com/thumbs/25x25x2/assets/images/noimage.png'} />
                    </a>
                  </div>
                  <div>
                    <a href="https://web.facebook.com/61565658563009" target="_blank" rel="noopener noreferrer" title="">
                      <img src="https://nicecupvn.com/upload/photo/mxhf1-38620.png" alt="Công Ty TNHH Quốc Tế Nice" onError={(e) => e.currentTarget.src = 'https://nicecupvn.com/thumbs/25x25x2/assets/images/noimage.png'} />
                    </a>
                  </div>
                </div>
                <div className="footer-news">
                  <h2 className="title-footer">{t('Sign up to receive a quote')}</h2>
                  <div className="text-center">
                  <a href="https://zalo.me/0931327609" target="_blank" rel="noopener noreferrer" title="">
                  <img className='qrcode' src={imageUrl} alt="Công Ty TNHH Quốc Tế Nice" onError={(e) => e.currentTarget.src = 'https://nicecupvn.com/thumbs/100x100x2/assets/images/noimage.png'} />
                  </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-news">
                <img src="https://nicecupvn.com/upload/photo/img-footer-2705.png" alt="Công Ty TNHH Quốc Tế Nice" onError={(e) => e.currentTarget.src = 'https://nicecupvn.com/thumbs/100x100x2/assets/images/noimage.png'} />
              </div>
            </div>
            <div className="col-md-5">
              <div className="footer-news">
                <div className="info-footer info-company">
                  <p><span style={{ fontSize: '16px' }}>{t('Address: Lot B-1, Dien Bien Phu Street, Dai Dang Industrial Park, Phu Tan Ward, Thu Dau Mot City, Binh Duong Province')} </span></p>
                  <p><span style={{ fontSize: '16px' }}>Hotline: 039 771 1112 - 0931 327 609 </span></p>
                  <p><span style={{ fontSize: '16px' }}>Email: monica.vn@qhcup.com</span></p>
                  <p><span style={{ fontSize: '16px' }}>Website: niceecopackaging.com </span></p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-news">
                <div id="footer-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125338.75550349108!2d106.6258646708481!3d10.975740308663676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174d1e7f6e7cb6d%3A0xe5f7da867b195106!2sCty%20tnhh%20nice!5e0!3m2!1svi!2s!4v1706081310579!5m2!1svi!2s"
                    width="600"
                    height="450"
                    style={{ border: '0' }}
                    title="Google Maps Location of Công Ty TNHH Quốc Tế Nice"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-chinhanh">
        <div className="maxwidth">
          <div className="row">
            <div className="col-md-4">
              <div className="chinhanh">
                <h3>{t('Branch')} 1</h3>
                <p>
                {t('Address: No. 9, Road 1, Hongsheng Industrial Park, Lichuan, Niushan, Dongcheng District, Dongguan City, Guangdong Province')}<br />
                Hotline: 039 771 1112 - 0931 327 609 <br />
                Email: monica.vn@qhcup.com<br />
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="chinhanh">
                <h3>{t('Branch')} 2</h3>
                <p>
                {t('Address: Lot B-1, Dien Bien Phu Street, Dai Dang Industrial Park, Phu Tan Ward, Thu Dau Mot City, Binh Duong Province')}<br />
                Hotline: 039 771 1112 - 0931 327 609<br />
                Email: monica.vn@qhcup.com<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-powered">
      <div className="maxwidth">
          <p className="copyright">{t('Công Ty TNHH Quốc Tế Nice đã đăng kí mã số thuế')}: 3702666149 </p>
        </div>
        <div className="maxwidth">
          <p className="copyright">Copyright by Công Ty TNHH Quốc Tế Nice. </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
