import React, { useState } from 'react';
import './ButtonQuote.css'; 

const ButtonQuote = () => {

  return (
    <div className='btn-booking'>
        <div className="animated infinite zoomIn kenit-alo-circle"></div>
      <div className="animated infinite pulse kenit-alo-circle-fill"></div>
      <img className='btn-booking' src="https://nicecupvn.com/assets/images/ic-baogia.png?v=1" alt="price icon" />
    </div>
  );
};

export default ButtonQuote;
