import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CustomNavbar from './components/navbar/navbar';
import ThemeProvider from 'react-bootstrap/esm/ThemeProvider';
import Footer from './components/Footer/Footer'; 
import HomePage from './homePage/homePage';
import Certificate from './Introduce/Certificate/Certificate';
import CompanyIntroduction from './Introduce/CompanyIntroduction/CompanyIntroduction'
import Introduce from './Introduce/Introduce';
import { useEffect, useState } from 'react';
import i18n from './i18n';
import Products from './products/products';
import PgFoF from './components/pgFOF'
import CategoryProductList from './products/component/categoryProductList/categoryProductList';
import ProductDetail from './products/component/ProductDetail/ProductDetail';
import Services from './Services/services';
import SupportSection from './Services/component/designAndPrintingSupport/supportSection';
import PurchasePaymentMethod from './Services/component/purchaseAndPayment/purchasePaymentMethod';
import Technology from './Technology/technology';
import PlasticProductionProcess from './Technology/component/sanphamnhua';
import PaperProductionProcess from './Technology/component/sanphamgiay';
import News from './News/news';
import AnnualMeeting from './News/component/AnnualMeeting/AnnualMeeting';
import EstablishedABranch from './News/component/EstablishedABranch/EstablishedABranch';
import ExhibitionLasVegas from './News/component/ExhibitionLasVegas/ExhibitionLasVegas';
import Contact from './Contact/Contact';
import CallButton from './components/CallButton/CallButton';
import NewsletterForm from './components/NewsletterForm/NewsletterForm';
import ButtonQuote from './components/ButtonQuote/ButtonQuote';
import ZaloButton from './components/ZaloButton/ZaloButton';
import MailButton from './components/MailButton/MailButton';
import SalesPolicy from './SupportPolicy/salesPolicy/salesPolicy';
import WarrantyPolicy from './SupportPolicy/warrantyPolicy/warrantyPolicy';
import PaymentPolicy from './SupportPolicy/paymentPolicy/paymentPolicy';
import SupportPolicy from './SupportPolicy/supportpolicy/supportpolicy';
import CacLoaiBaoBi from './News/component/AnnualMeeting/AnnualMeeting';
import Snowfall from './components/Snowfall/Snowfall';
function App() {
  const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
  useEffect(() => {
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  return (
    <ThemeProvider>
      <Router>
        <Snowfall/>
    <CustomNavbar />
    <div  onClick={handleOpenModal}><ButtonQuote /></div >
    {showModal && <NewsletterForm isshow={true}/>}
    <ZaloButton/>
    <MailButton/>
    <Routes>
      <Route exact path="/" element={<HomePage/>} />
      <Route exact path="Introduce" element={<Introduce/>} />
      <Route exact path="Companyintroduction" element={<CompanyIntroduction/>} />
      <Route exact path="Certificate" element={<Certificate/>} />
      <Route exact path="Products" element={<Products/>} >
        <Route path=":uid" element={<CategoryProductList />}>
        
        </Route>
      </Route>
       <Route path="/Products/:uid/:id" element={<ProductDetail />} >
      </Route> 
      <Route exact path="service" element={<Services/>} />
      <Route path='service/design-and-printing-support' element={<SupportSection/>}/>
      <Route path='service/purchase-payment-method' element={<PurchasePaymentMethod/>}/>
      <Route exact path="technology" element={<Technology/>} />
      <Route exact path="technology/plastic-production-process" element={<PlasticProductionProcess/>} />
      <Route exact path="technology/paper-production-process" element={<PaperProductionProcess/>} />
      <Route exact path="news" element={<News/>} />
      <Route exact path="news/annual-meeting" element={<AnnualMeeting/>} />
      <Route exact path="news/establishe-a-branch" element={<EstablishedABranch/>} />
      <Route exact path="news/exhibition-las-vegas" element={<ExhibitionLasVegas/>} />
      <Route exact path="news/cac-loai-bao-bi" element={<CacLoaiBaoBi/>} />
      <Route exact path="contact" element={<Contact/>} />
      <Route path='*' element={<PgFoF/>}/>
      <Route exact path="NewsletterForm" element={<NewsletterForm/>}/>
      <Route exact path="sales-policy" element={<SalesPolicy/>}/>
      <Route exact path="warranty-policy" element={<WarrantyPolicy/>}/>
      <Route exact path="payment-policy" element={<PaymentPolicy/>}/>
      <Route exact path="support-policy" element={<SupportPolicy/>}/>
      
    </Routes>
    
    <CallButton />
    <Footer /> 
  </Router>
    </ThemeProvider>
    
  );
}

export default App;

