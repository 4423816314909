// src/components/Snowfall.js
import React, { useEffect, useState } from 'react';
import './Snowfall.css';

const Snowfall = () => {
  const [flakes, setFlakes] = useState([]);

  // Số lượng bông tuyết
  const NUMBER_OF_FLAKES = 40;

  useEffect(() => {
    const generateFlakes = () => {
      const newFlakes = Array.from({ length: NUMBER_OF_FLAKES }).map(() => ({
        left: Math.random() * 100 + 'vw', // Vị trí ngang ngẫu nhiên
        animationDuration: Math.random() * 5 + 5 + 's', // Thời gian rơi ngẫu nhiên
        animationDelay: Math.random() * 5 + 's', // Độ trễ ngẫu nhiên
        size: Math.random() * 13 + 8 + 'px', // Kích thước ngẫu nhiên từ 10px - 20px
      }));
      setFlakes(newFlakes);
    };

    generateFlakes();
  }, []);

  return (
    <>
      {flakes.map((flake, index) => (
        <div
          key={index}
          className="snowflake"
          style={{
            left: flake.left,
            animationDuration: flake.animationDuration,
            animationDelay: flake.animationDelay,
            fontSize: flake.size, // Áp dụng kích thước ngẫu nhiên
          }}
        >
          ❄
        </div>
      ))}
    </>
  );
};

export default Snowfall;
