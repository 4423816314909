import React, { useEffect } from 'react';
import './NewProduct.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";

const NewProducts = () => {
  useEffect(() => {
    console.log(localStorage.getItem('language'));
    // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);
const { t } = useTranslation();
const currentLanguage = i18n.language;
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
 
  };

  const products = [
    {
      id: 1,
      name: 'Ly giấy hình vuông',
      image: '/images/san-pham-moi/ly-giay-hinh-vuong.jpg',
      nameen:'Square paper cup',
      href:'/Products/1/05',
    },
    {
      id: 2,
      name: 'Tô Giấy Hình Vuông',
      image: '/images/san-pham-moi/to-giay-vuong.jpg',
      nameen:'Square Paper Bowl',
      href:'/Products/3/31',
    },
    {
      id: 3,
      name: 'Tô Giấy Hình Chữ Nhật',
      image: '/images/san-pham-moi/to-giay-chu-nhat.jpg',
      nameen:'Rectangular Paper Bowl',
      href:'/Products/3/32',
    },
    {
      id: 4,
      name: 'Tô Giấy Đựng Kem',
      image: '/images/san-pham-moi/ly-kem-giay.jpg',
      nameen:'Paper Ice Cream Bowl',
      href:'/Products/3/24',
    },
    {
      id: 5,
      name: 'Ly Giấy Ánh Kim',
      image: '/images/san-pham-moi/ly-anh-kim.jpg',
      nameen:'Metallic Paper Cups',
      href:'/Products/1/16',
    },
    {
      id: 6,
      name: 'Tô Giấy, Ly Giấy Tráng Bạc',
      image: '/images/san-pham-moi/to-giay-bac.jpg',
      nameen:'Silver Coated Paper Bowls And Cups',
      href:'/Products/3/23',
    },
    {
      id: 7,
      name: 'Hộp Sushi',
      image: '/images/san-pham-moi/hop-sushi.jpg',
      nameen:'Sushi Box',
      href:'/Products/4/37',
    },
    {
      id: 8,
      name: 'Hộp Pizza',
      image: '/images/san-pham-moi/hop-pizza.jpg',
      nameen:'Pizza Box',
      href:'/Products/4/43',
    },
    {
      id: 9,
      name: 'Khay Bã Mía Nhiều Ngăn',
      image: '/images/san-pham-moi/san-pham-ba-mia.png',
      nameen:'Multi-compartment Tray',
      href:'/Products/8/55',
    },
    {
      id: 10,
      name: 'Ly Nhựa Cầu Vồng',
      image: '/images/san-pham-moi/ly-cau-vong.jpg',
      nameen:'Rainbow Plastic Cup',
      href:'/Products/2/06',
    },
  ];

  return (
    <section className="partners-carousel">
      <h2 className='title'><strong>{t('NEW PRODUCTS')}</strong></h2>
      <Slider {...settings}>
        {products.map((product) => (
          <a href={product.href} className="product-card">
            <Card >
          <Card.Img src={product.image} alt='Công Ty TNHH Quốc tế Nice' ></Card.Img>
          <Card.Body>
          <Card.Title className="single-line-ellipsis">{(currentLanguage === "en" )? product.nameen:product.name}</Card.Title>
          <Card.Text style={{color:'red'}}>{(currentLanguage === "en" )? "Contact" :"Liên hệ"}</Card.Text>
          </Card.Body>
         
        </Card></a>
          
        ))}
      </Slider>
    </section>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-next" onClick={onClick}></div>;
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return <div className="slick-arrow slick-prev" onClick={onClick}></div>;
};

export default NewProducts;
