import React, { useEffect } from 'react';
import { Container, Row, Col,  Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";
import { FaCalendarWeek } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const SupportPolicy = () => {
    useEffect(() => {
        console.log(localStorage.getItem('language'));
        // Lấy ngôn ngữ từ localStorage khi ứng dụng khởi động
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
        
      }, []);
    const { t } = useTranslation();
  return (
    <div id="main" className="wrap-main" >
      <Helmet>
            <title>{t('Support policy')} - Công Ty TNHH Quốc Tế Nice </title>
            <meta name="description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"/>
            <meta name="keywords" content="Giới thiệu công ty TNHH Quốc Tế Nice, Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={t('Support policy')}></meta>
            <meta property="og:description" content="Quý khách vui lòng liên hệ tư vấn & đặt hàng theo Hotline: 0931 327 609 hoặc 0814 368 074. Địa chỉ: Lô B-1, Đ. Điện Biên Phủ, KCN Đại Đăng, p. Phú Tân, TP Thủ Dầu Một, Bình Dương"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
        <Container className="maxwidth">
        <div >
          <Row>
            <Col md={12}>
            <Breadcrumb>
        <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
        <Breadcrumb.Item  active>
        {t('Support policy')} 
        </Breadcrumb.Item>
      </Breadcrumb>
              <div className="title-main">
                <h1><strong>{t("SUPPORT POLICY")}</strong></h1>
              </div>
              <div className="time-main">
                
                <span><FaCalendarWeek/> {t("Posted date")}: 05/22/2022 08:27 AM</span>
              </div>
              <div className="content-detail">
                <div className="w-clear" id="toc-content">
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>1. {t("Communication channels")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("The communication channels customers can use to request support, including email, phone, website, or social media.")}
                  </p>
                  
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>2. {t("Response time")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("The time the company commits to respond after receiving a support request, ensuring speed and efficiency.")}
                  </p>
                 
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    <strong>3. {t("Support team")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px' }}>
                    - {t("Support team, skills and expertise to ensure customers receive quality support.")}
                  </p>
                  
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>4. {t("Instructions for use")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Provide product or service user manuals to help customers take full advantage of the features.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>5. {t("Return policy")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Methods and conditions for product returns, including time and regulations related to returns.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>6. {t("Warranty policy")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("If applicable, provide details of the warranty policy, its duration and relevant conditions.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>7. {t("Dispute Resolution")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Dispute resolution process, including how to resolve complaints and specific steps to resolve the issue.")}
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    <strong>8. {t("Customer feedback")}:</strong>
                  </p>
                  <p className="text-justify" style={{ fontSize: '16px'}}>
                    - {t("Collect feedback from customers to continuously improve the quality of support services.")}
                  </p>
                  
                </div>
                
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      </div>
  );
};

export default SupportPolicy;
