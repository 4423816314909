import React from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GrMail } from "react-icons/gr";
import './MailButton.css'

const MailButton = () => {
  return (
    <a
      className="btn-mail btn-frame text-decoration-none hidden-xs"
      href="mailto:monica.vn@qhcup.com"
      
    >
      <div className="animated infinite zoomIn kenit-alo-circle" style={{border: '2px solid #65b741'}}></div>
      <div className="animated infinite pulse kenit-alo-circle-fill" ></div>
      <div className="mail-icon-container" >
      <GrMail className="mail-icon"  src="https://nicecupvn.com/assets/images/zl.png" alt="Mail" />
      </div>
    </a>
  );
};

export default MailButton;
