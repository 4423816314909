import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Image, Breadcrumb, Tabs, Tab } from 'react-bootstrap';
import './ProductDetail.css';
import { BiSolidCategory } from "react-icons/bi";
import DOMPurify from 'dompurify';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";
import SameProductList from './component/sameProduct';
import { db } from "../../../api/googleApi"; // Thêm Firestore db từ file cấu hình firebase
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { BsFillPhoneFill } from "react-icons/bs";
import { Helmet } from 'react-helmet';



const ProductDetail = () => {
    const [selectedImage, setSelectedImage] = useState('');
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const currentLanguage = i18n.language;
    const storage = getStorage(); // Initialize Firebase Storage

    const { t } = useTranslation();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                // Lấy dữ liệu sản phẩm từ Firestore
                const productDoc = doc(db, 'products', id); // Lấy sản phẩm theo id từ Firestore
                const productSnap = await getDoc(productDoc);

                if (productSnap.exists()) {
                    const productData = productSnap.data();
                    setProduct(productData);

                    // Tăng số lượt xem
                    const newViewCount = productData.view ? productData.view + 1 : 1;
                    await updateDoc(productDoc, { view: newViewCount }); // Cập nhật view lên Firestore
                    
                    

                   if (productData.images && productData.images.length > 0) {
                        setSelectedImage(productData.images[0]);
                    }
                } else {
                    console.log("Sản phẩm không tồn tại!");
                }
                
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchProduct();
    }, [id, storage]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    const name = (currentLanguage === "en") ? product.name : product.namevn;
    const price = (currentLanguage === "en") ? product.Price : "Liên Hệ";
    const safeInfomation = (currentLanguage === "en") ? DOMPurify.sanitize(product.information) : DOMPurify.sanitize(product.informationvn);
    const safeDescript = (currentLanguage === "en") ? DOMPurify.sanitize(product.description) : DOMPurify.sanitize(product.descriptionvn);
    function capitalizeFirstLetter(str) {
        if (!str) return str;
  return str
    .split(' ') // Tách chuỗi thành mảng các từ
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Viết hoa chữ cái đầu và chuyển phần còn lại thành chữ thường
    .join(' '); // Nối các từ lại thành chuỗi
      }
    return (
        <div className='main-detail-product ' >
            <Helmet>
            <title>{capitalizeFirstLetter(name)} Chất Lượng Cao Giá Rẻ, Đầy Đủ Kích Thước, In Ấn Theo Yêu Cầu </title>
            <meta name="description" content="Chuyên sản xuất các loại Ly nhựa, ly giấy, tô giấy, chai nhựa, ống hút, muỗng nhựa, khăn giấy... Uy tín, chất lượng hàng đầu Việt Nam. Liên hệ ngay để được báo giá sớm nhất"/>
            <meta name="keywords" content={name + ", Bao bì , ly nhựa, ly giấy, tô giấy, hộp nhựa, khay giấy, ống hút nhựa, muỗng nhựa, khăn giấy, sản phẩm bảo vệ môi trường, sản phẩm dùng 1 lần"}/>
            <meta name="robots" content="noodp,index,follow"></meta>
            <link rel="icon" href="./logo.png" />
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={capitalizeFirstLetter(name)}></meta>
            <meta property="og:description" content="Chuyên sản xuất các loại Ly nhựa, ly giấy, tô giấy, chai nhựa, ống hút, muỗng nhựa, khăn giấy... Uy tín, chất lượng hàng đầu Việt Nam. Liên hệ ngay để được báo giá sớm nhất"></meta>
            <meta property="og:url" content="https://niceecopackaging.com/"></meta>
            <meta property="og:site_name" content="Công Ty TNHH Quốc Tế Nice"></meta>
      </Helmet>
            <Container className="mt-4" style={{maxWidth: "100%" , width:"100%"}} >
                <Breadcrumb  >
                    <Breadcrumb.Item href="/">{t('Home')}</Breadcrumb.Item>
                    <Breadcrumb.Item href="/Products">{t('Products')}</Breadcrumb.Item>
                    <Breadcrumb.Item href={`/Products/${product.category.id}`}>
                    {t(`${product.category.name}`)}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{name}</Breadcrumb.Item>
                </Breadcrumb>
                <Row>
                    <Col md={5}>
                        <Image src={selectedImage} fluid />
                        <div className="mt-3 d-flex">
                            <div className="thumbnail-scroll">
                                {product.images && product.images.map((url, idx) => (
                                    <Image
                                        key={idx}
                                        src={url}
                                        thumbnail
                                        onClick={() => setSelectedImage(url)}
                                        style={{
                                            width: "100px",
                                            height: "auto",
                                            cursor: "pointer",
                                            border: selectedImage === url ? "2px solid #007bff" : "none"
                                        }}
                                        className="mb-2"
                                    />
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col md={7} className='right-detail-product'>
                        <h1 style={{color:'#F59F05'}}><strong>{capitalizeFirstLetter(name)}</strong></h1>
                        <p style={{padding:"5px"}}><BiSolidCategory style={{ fontSize:'20px',color:"green"}} /> {t("Category")}: <strong>{t(`${product.category.name}`)} </strong></p>
                        <button style={{border:"2px solid #F59F05" , fontSize:"16px", marginBottom:'15px', borderRadius:"25px", padding:"5px"}}><FaMoneyCheckDollar style={{fontSize:'20px', color:"green"}}/> {t("Price")}:<strong style={{color:"red" }}> {price}</strong> </button>
                        <div dangerouslySetInnerHTML={{ __html: safeDescript }} />
                        <button href="tel:0931 327 609" title="0931 327 609" style={{border:"2px solid #F59F05" , color:"red", fontSize:"20px", marginBottom:'15px', borderRadius:"10px"}}><strong><BsFillPhoneFill  style={{color:"green"}}/> {t("Call now")}: 0931 327 609</strong></button>
                        <p><strong>{t("View")}:</strong> {product.view}</p>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="info" id="product-tabs" className="custom-tabs">
                    <Tab eventKey="info" title="Thông tin sản phẩm">
                        <div className='safeInfomation' dangerouslySetInnerHTML={{ __html: safeInfomation }} />
                    </Tab>
                    <Tab eventKey="comments" title="Bình luận">
                        {/* <Comment productId={product.id} /> */}
                    </Tab>
                </Tabs>
            </Container>
            <Col>
                <div>
                    <h2 style={{ textAlign: 'center', paddingTop: '40px', paddingBottom: '40px', color: "#F59F05" }}>
                        <strong>{t("SIMILAR PRODUCTS")}</strong>
                    </h2>
                    <SameProductList id={product.category.id} />
                </div>
            </Col>
        </div>
    );
};

export default ProductDetail;
